import { Types } from '@riskforge/jawa-core';
import { colors, layout, text } from '@riskforge/jawa-theme';
import { DateTime } from 'luxon';
import { isDate } from 'lodash';

import {
  Icons,
  Link,
  React,
  styled,
  useTranslation,
} from '@riskforge/platform-web';
import { Signature } from 'components';

interface ReportListProps {
  className?: string;
  reports: Types.Reports;
}

const ReportList: React.FunctionComponent<ReportListProps> = ({ className, reports }) => (
  <StyledReportList className={className}>
    {Object.values(reports).map((report: Types.KeyedReport) => (
      <ReportItem key={`report-${report.id}`} report={report} />
    ))}
  </StyledReportList>
);

const ReportItem: React.FunctionComponent<ReportItemProps> = ({
  className,
  report,
}) => {
  const { t } = useTranslation();
  const reportSigned = (): boolean => report.signature !== undefined;
  const reportDueDeltaDays = (): number => (new Date().getTime() - report.due.getTime()) / 1000 / 60 / 1440;
  const reportOverdue = (): boolean => !reportSigned() && reportDueDeltaDays() > 0;
  const reportDueDelta = (): string => t(reportOverdue() ? 'daysLate' : 'days', { days: Math.round(Math.abs(reportDueDeltaDays())) });
  const reportStatusLabel = (): string => (reportOverdue() ? t('overdue') : t('dueIn'));
  const reportPendingStatus = (): React.ReactNode => (
    <>
      <p>{reportStatusLabel()}</p>
      <p>{reportDueDelta()}</p>
    </>
  );
  const reportStatus = (): React.ReactNode => (report.signature ? <Signature signature={report.signature} /> : reportPendingStatus());
  const reportOutstandingIcon = (): React.ReactNode => (reportOverdue() ? <Icons.fa.FaExclamationCircle /> : <Icons.fa.FaChevronRight />);
  const reportIcon = (): React.ReactNode => (report.signature ? <Icons.fa.FaCheck /> : reportOutstandingIcon());
  const completeBefore = (due: Date): React.ReactNode => (
    <>
      <p>{t('completeBefore')}</p>
      <p>{t('formattedDate', { date: due })}</p>
    </>
  );
  const digitallySigned = (signature: Types.Signature): React.ReactNode => {
    const dateString = isDate(signature.timestamp) ? signature.timestamp.toUTCString() : signature.timestamp;
    const signedDateTime = DateTime.fromISO(dateString);
    return (
      <>
        <p>{t('digitallySigned')}</p>
        <p>{signedDateTime.isValid && signedDateTime.toFormat('ff')}</p>
      </>
    );
  };

  const reportNextStep = (): React.ReactNode => (report.signature ? digitallySigned(report.signature) : completeBefore(report.due));
  return (
    <Link to={`/report/${encodeURIComponent(report.id)}`}>
      <StyledReportItem className={className} overdue={reportOverdue()} signed={reportSigned()}>
        <ReportLabel>
          <span>{report.label}</span>
          &nbsp;
          <span>{t('report')}</span>
        </ReportLabel>
        <ReportMetric>
          {reportNextStep()}
        </ReportMetric>
        <ReportMetric>
          {reportStatus()}
        </ReportMetric>
        {reportIcon()}
      </StyledReportItem>
    </Link>
  );
};

interface ReportItemProps {
  className?: string;
  report: Types.KeyedReport;
}

const StyledReportList = styled.ul`
  overflow-y: scroll;
  width: 100%;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  &::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
  }
`;

const ReportLabel = styled.div`
  span {
    font-weight: ${text.reportList.labelWeight};
    font-size: ${text.reportList.labelSize};
  }
`;

const ReportMetric = styled.div`
  * {
    font-size: ${text.reportList.metricSize};
    line-height: ${text.reportList.metricLine};
  }
  > :first-child {
    font-weight: ${text.reportList.metricLabelWeight};
  }
`;

const StyledReportItem = styled.li<StyledReportItemProps>`
  box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.5);
  &:hover {
    box-shadow: 5px 9px 10px 0px rgba(0,0,0,0.25);
    transform: translate(-0.1rem, -0.1rem);
    opacity: 1;
  }
  width: 95%;
  height: 5rem;
  background-color: ${colors.widgetBackground};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: ${layout.widgetMargin};
  padding: ${layout.widgetPadding};
  border-radius: ${layout.widgetBorderRadius};
  cursor: pointer;
  opacity: ${({ signed }: { signed: boolean }): string => (signed ? '40%' : '80%')};
  > * {
    flex: 1 1 1rem;
    max-width: 33%;
  }
  > *:first-child span:last-child {
    @media (max-width: 720px) {
      display: none;
    }
  }
  > *:nth-child(2) {
    @media (max-width: 720px) {
      display: none;
    }
  }
  > *:nth-last-child(2) {
    @media (max-width: 720px) {
      max-width: 50%;
    }
    * {
      color: ${({ overdue }: { overdue: boolean }): string => (overdue ? colors.warn : colors.text)} !important;
    }
  }
  > *:last-child {
    flex: 0 0 ${layout.reportList.reportStatusIconSize};
    font-size: ${layout.reportList.reportStatusIconSize};
    @media (max-width: 720px) {
      font-size: calc(${layout.reportList.reportStatusIconSize} * 0.75);
    }
    fill: ${({ signed, overdue }: { signed: boolean; overdue: boolean }): string => {
    const unsignedFill = (overdue ? colors.warn : colors.text);
    return signed ? colors.ok : unsignedFill;
  }};
  }
`;

interface StyledReportItemProps {
  overdue: boolean;
  signed: boolean;
}

export default ReportList;

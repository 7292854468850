import { Types } from '@riskforge/jawa-core';
import {
  values, size, filter, divide,
} from 'lodash';

import {
  React,
  styled,
  Icons,
  useTranslation,
} from '@riskforge/platform-web';
import { SectionEdit, ReportNav } from 'components';
import {
  colors,
  // layout
} from '@riskforge/jawa-theme';

interface ReportEditProps {
  className?: string;
  report: Types.KeyedReport;
  readonly?: boolean;
  reportError?: Error;
  uploadDocument?: (sectionId: string, questionId: string, documents: File) => void;
  removeDocument?: (sectionId: string, questionId: string, documentId: string) => void;
  createAttestation?: (sectionId: string, questionId: string, attestation: Types.AttestationOption, explanation?: string) => void;
}

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 18rem auto;
  background-color: ${colors.reportBackground};
`;
const SectionComplete = styled.div`
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 1rem;
  color: ${colors.success};

  & > p {
    width: 5rem;
    text-align: center;
    font-weight: 700;
    font-size: 0.9rem;
    margin-left: 0.5rem;
    align-items: center;
  }
  & > svg {
    font-size: 2rem;
  }
`;
const NextButton = styled.div`
  font-size: 1rem;
  color: ${colors.text};
  background: ${colors.active};
  font-weight: 700;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  margin-left: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
  & > svg {
    font-size: 1.2rem;
    margin-left: 1rem;
  }
`;

const ReportEdit: React.FunctionComponent<ReportEditProps> = ({
  className,
  report,
  readonly = false,
  reportError,
  uploadDocument,
  removeDocument,
  createAttestation,
}) => {
  const { t } = useTranslation();
  const [activeReportIndex, setActiveReportIndex] = React.useState<number>(0);

  const activeSection = values(report.sections)[activeReportIndex];

  const answeredQuestions = size(filter(activeSection.questions, (q) => !!q.attestation?.attestation));
  const remainingFraction = divide(answeredQuestions, activeSection?.progress?.totalQuestions);
  const isSectionComplete = remainingFraction === 1;

  const isSelectedLastItem = activeReportIndex + 1 === size(report.sections);

  return (
    <Wrapper className={className}>
      <ReportNav
        report={report}
        activeReportIndex={activeReportIndex}
        setActiveReportIndex={setActiveReportIndex}
      />
      <SectionEdit
        readonly={readonly}
        section={activeSection}
        instructions={report?.instructions}
        uploadDocument={uploadDocument}
        removeDocument={removeDocument}
        createAttestation={createAttestation}
        reportError={reportError}
      />
      {!readonly && isSectionComplete && !isSelectedLastItem && (
        <SectionComplete>
          <NextButton onClick={(): void => setActiveReportIndex(activeReportIndex + 1)}>
            {t('nextBtn')}
            <Icons.fa.FaArrowRight />
          </NextButton>
          <p>{t('sectionCompleteMsg')}</p>
          <Icons.fa.FaCheckCircle />
        </SectionComplete>
      )}
    </Wrapper>
  );
};

export default ReportEdit;

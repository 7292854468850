import { colors } from '@riskforge/jawa-theme';
import { React, styled } from '@riskforge/platform-web';
import { round } from 'lodash';

const Progress: React.FunctionComponent<ProgressProps> = ({ className, progress, label }) => {
  const percentComplete = round(progress * 100);
  return (
    <Wrapper>
      <Bar className={className}>
        <Done size={progress} />
      </Bar>
      <div>{label ? `${percentComplete}%` : undefined}</div>
    </Wrapper>
  );
};

interface ProgressProps {
  className?: string;
  progress: number;
  label?: boolean;
}

const Wrapper = styled.div`
    width: 100%;
    height: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    > * {
        line-height: 1.6rem;
        font-size: 1.25rem;
        font-weight: 600;
    }
    padding: 0.25rem;
`;

const Bar = styled.div`
    background-color: ${colors.text};
    border-radius: 1rem;
    width: 65%;
    height: 60%;
`;

const Done = styled.div<InnerProgress>`
    background-color: ${colors.success};
    width: ${({ size }): number => size * 100}%;
    height: 100%;
`;

interface InnerProgress {
  size: number;
}

export default Progress;

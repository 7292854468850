import {
  React, styled, useTranslation,
} from '@riskforge/platform-web';
import { colors } from '@riskforge/jawa-theme';
import { AttestationOption } from '@riskforge/jawa-core/dist/types';

interface ExplanationProps {
  initValue: string;
  section: string;
  question: string;
  attestation: AttestationOption;
  handleUpdate: (
    sectionId: string,
    questionId: string,
    attestation: AttestationOption,
    explanation: string,
  ) => void;
}

const ExplanationContainer = styled.form`
  margin: 1rem 0;
`;
const ExplanationInput = styled.textarea<{ isOptional: boolean }>`
  padding: 0.5rem;
  border: 1px solid ${(p): string => (p.isOptional ? '#555' : colors.error)};
  border-radius: 0.5rem;
  width: 100%;
  resize: none;
  overflow: auto;
  font-family: 'Roboto', sans-serif;
  font-size: 0.8rem;
`;
const ExplanationTitle = styled.div<{ isOptional: boolean }>`
  font-size: 0.8rem;
  font-weight: 700;
  margin: 0 0 0.5rem;
  color: ${(p): string => (p.isOptional ? '#555' : colors.error)};
`;

const Explanation: React.FC<ExplanationProps> = ({
  initValue, section, question, attestation, handleUpdate,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState<string>(initValue);

  const handleChange = React.useCallback(
    (event) => {
      setValue(event.target.value);
    },
    [setValue],
  );

  const attestationString = attestation ? t(attestation) : '';

  return (
    <ExplanationContainer>
      <ExplanationTitle isOptional={attestation === 'COMPLIANT'}>
        {t('explanation', { attestationString })}
      </ExplanationTitle>
      <ExplanationInput
        rows={6}
        value={value}
        onChange={handleChange}
        onBlur={(event_: any): void => handleUpdate(section, question, attestation, event_.target.value)}
        isOptional={attestation === 'COMPLIANT'}
      />
    </ExplanationContainer>
  );
};

export default Explanation;

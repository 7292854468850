import { filter, size } from 'lodash';
import { State } from '@riskforge/jawa-app-state';
import {
  Div100vh,
  GlobalContext,
  React,
  ViewProperties,
  useDispatch,
  useSelector,
  useTranslation,
  useAuth0,
} from '@riskforge/platform-web';
import {
  AccountInfo,
  Error,
  ReportList,
  ReportListTitle,
  StyledContainer,
} from 'components';
import { GlobalContext as JawaContext } from 'contexts';

const ReportListView: React.FunctionComponent<ViewProperties> = () => {
  const { t } = useTranslation();
  const { actionCreator } = React.useContext(GlobalContext) as JawaContext;
  const [reportListError, setReportListError] = React.useState<Error | undefined>(undefined);
  const dispatch = useDispatch();
  const {
    getTokenSilently, user,
  } = useAuth0();

  React.useEffect(() => {
    if (user) {
      (async (): Promise<void> => {
        const token = await getTokenSilently();
        actionCreator
          .listReports(dispatch, token, user['https://app_metadata.com/'].companyId as string)
          .catch(setReportListError);
      })();
    }
  }, [getTokenSilently, actionCreator, user, dispatch]);

  const reports = useSelector((state: State) => state.reports);

  const pendingCount = size(filter(reports, (r) => !r?.signature));

  if (!user) return <div />;
  const validUser = {
    ...user,
    id: user.sub,
    fullName: user.name,
    email: user.email,
  };

  const noPending = (
    <AccountInfo>
      <h1>{t('noPendingReportsMessage')}</h1>
      <p>{t('noPendingReportsNextStep')}</p>
    </AccountInfo>
  );
  const singlePending = (
    <AccountInfo>
      <h1>{t('singlePendingReportsMessage')}</h1>
      <p>{t('singlePendingReportsNextStep')}</p>
    </AccountInfo>
  );
  const multiplePending = (
    <AccountInfo>
      <h1>{t('multiplePendingReportsMessage')}</h1>
      <p>{t('multiplePendingReportsNextStep')}</p>
    </AccountInfo>
  );
  return (
    <Div100vh>
      <StyledContainer user={validUser}>
        { reportListError ? <Error error={reportListError} /> : undefined}
        { pendingCount === 0 ? noPending : (pendingCount === 1 ? singlePending : multiplePending) }
        <ReportListTitle>{t('yourReports')}</ReportListTitle>
        <ReportList reports={reports} />
      </StyledContainer>
    </Div100vh>
  );
};

export default ReportListView;

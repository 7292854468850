import {
  map, omit, size, toArray, forEach, isEmpty,
} from 'lodash';
import { Types } from '@riskforge/jawa-core';
import {
  React, styled, useTranslation, Icons, Link,
} from '@riskforge/platform-web';
import { colors } from '@riskforge/jawa-theme';
import { AttestationOption } from '@riskforge/jawa-core/dist/types';
import { Error, Explanation } from 'components';
import Markdown from 'markdown-to-jsx';

interface SectionEditProps {
  className?: string;
  section?: Types.KeyedSection;
  instructions?: string | React.ReactElement<any>;
  readonly?: boolean;
  reportError?: Error;
  uploadDocument?: (sectionId: string, questionId: string, documents: File) => void;
  removeDocument?: (sectionId: string, questionId: string, documentId: string) => void;
  createAttestation?: (sectionId: string, questionId: string, attestation: Types.AttestationOption, explanation?: string) => void;
}

const MainArea = styled.div`
  position: relative;
  height: auto;
  overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  &::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
  }
`;
const ContentWrapper = styled.div`
  margin: 0 0 5rem;
`;
const Button = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  user-select: none;
  padding: 0.5rem 1rem;
  display: grid;
  align-items: center;
  grid-gap: 1rem;
  grid-template-columns: 1rem auto;
  background: ${(p): string => (p.isActive ? colors.activeBg : colors.inactiveBg)};
  color: ${(p): string => (p.isActive ? colors.active : colors.inactive)};
  font-size: 0.9rem;
  font-weight: 700;
  border-radius: 0.5rem;
  width: 12rem;
  & > * {
    justify-self: center;
  }
  &:hover {
    opacity: 0.8;
  }
`;
const TitleBanner = styled.div`
  background: ${colors.bannerBg};
  color: ${colors.mainText};
  position: fixed;
  top: 4rem;
  width: calc(100% - 18rem);
  height: 4rem;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding: 1rem 2rem;
  & > * {
    margin-left: 1rem;
  }
`;
const CloseButton = styled(Link)`
  cursor: pointer;
  user-select: none;
`;
const SectionContainer = styled.div`
  width: calc(100% - (2 * 2rem));
  max-width: 80rem;
  margin: 4rem auto 0;
  padding: 2rem;
  color: ${colors.mainText};
  overflow-y: auto;
`;
const SectionTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0 0 1rem;
`;
const ContentBox = styled.div`
  background: ${colors.contentBoxBg};
  box-shadow: 0 0 0.5rem 0 ${colors.shadow};
  border-radius: 0.5rem;
`;
const SectionTableHeader = styled.div<{ columnLength: number }>`
  display: grid;
  grid-template-columns: ${(p): string => (`2fr repeat(${p.columnLength - 1}, 1fr) 15rem`)};
  grid-gap: 0.5rem;
  align-items: center;
  border-bottom: 1px solid ${colors.shadow};
  padding: 1rem;
`;
const SectionHeaderItem = styled.div`
  font-weight: 700;
  font-size: 0.8rem;
  text-align: center;
  &:first-child {
    text-align: left;
  }
`;
const SectionQuestionRow = styled.div<{ columnLength: number }>`
  display: grid;
  grid-template-columns: ${(p): string => (`2fr repeat(${p.columnLength - 1}, 1fr) 15rem`)};
  grid-gap: 0.5rem;
  align-items: start;
  border-bottom: 1px solid ${colors.shadow};
  padding: 1rem;
  &:last-child {
    border-bottom: none;
  }
`;
const SectionQuestionItem = styled.div`
  text-align: center;
  &:first-child {
    text-align: left;
  }
`;
const DocumentsContainer = styled.div`
  margin: 1rem 0;
`;
const DocumentDescription = styled.div`
  font-weight: 700;
`;
const DocumentError = styled.div`
  font-size: 0.8rem;
  color: ${colors.warn};
  font-weight: 700;
  text-align: center;
  margin: 0.5rem 0;
`;
const DocumentsList = styled.div`
  margin: 0 0 1rem;
`;
const DocumentItem = styled.div`
  display: grid;
  grid-template-columns: 1rem auto 1rem;
  grid-gap: 0.5rem;
  align-items: center;
`;
const DocumentName = styled.div`
  text-align: left;
  font-size: 0.8rem;
  font-weight: 700;
`;
const DocumentRemoveButton = styled.div`
  cursor: pointer;
  user-select: none;
  opacity: 0.3;
  &:hover {
    opacity: 1;
  }
`;
const AttestationWrapper = styled.div <{ gridSize: number }>`
  display: grid;
  grid-template-columns: repeat(${(p): number => p.gridSize}, 1fr);
  border: 1px solid ${colors.shadow};
  border-radius: 0.5rem;
  align-items: stretch;
`;
const AttestationButton = styled.div <{ isSelected: boolean; isInactive: boolean; attestation?: AttestationOption }>`
  color: ${(p): string => (p.isSelected ? colors.text : (p.isInactive ? colors.shadow : colors.mainText))};
  background: ${(p): string => (p.isSelected && p.attestation ? colors[p.attestation] : colors.contentBoxBg)};
  font-weight: ${(p): string => (p.isSelected ? '700' : '400')};

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  padding: 0.5rem 0;
  border-left: 1px solid ${colors.shadow};
  cursor: pointer;
  user-select: none;
  &:first-child {
    border-left: none
  }
`;
export const AddFileInput = styled.label`
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;

  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 1rem auto;
  font-size: 0.8rem;

  padding: 0.5rem 0;
  width: 8rem;
  border-radius: 0.3em;
  text-align: center;
  font-weight: 700;
  background: ${colors.activeBg};
  color: ${colors.active};

  &:hover { opacity: 0.85; }
  &:active { box-shadow: none; }

  & > svg {
    font-size: 1.5em;
    line-height: 0;
  }

  input[type=file] {
    border: 0;
    clip: rect(0, 0, 0, 0);
    top: 0;
    left: 0;
    height: 100px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    white-space: nowrap;
    width: 100%;
  }
`;
const CloseIcon = styled.div`
  cursor: pointer;
  user-select: none;
`;
const InstructionsTitleBar = styled.div`
  display: flex;
  justify-content: space-between;
`;
const InstructionsTitle = styled.div`
  font-size: 2rem;
  font-weight: 700;
  margin: 0 0 1rem;
`;

const SectionEdit: React.FunctionComponent<SectionEditProps> = ({
  readonly = false,
  section,
  instructions,
  reportError,
  uploadDocument = (): void => { },
  removeDocument = (): void => { },
  createAttestation = (): void => { },
}) => {
  const { t } = useTranslation();
  const [pendingAttestation, setPendingAttestation] = React.useState<{ [id: string]: Types.AttestationOption }>({});
  const [isInstructionsOpen, setIsInstructionsOpen] = React.useState<boolean>(false);

  const handleUpload = (event: any, sectionId: string, questionId: string): void => {
    event.preventDefault();
    const files = toArray(event.target.files);
    forEach(files, (document: File) => uploadDocument(sectionId, questionId, document));

    if (pendingAttestation[`${sectionId}${questionId}`] === 'COMPLIANT') {
      createAttestation(sectionId, questionId, pendingAttestation[`${sectionId}${questionId}`]);
      setPendingAttestation(omit(pendingAttestation, `${sectionId}${questionId}`));
    }
  };

  if (!section) return <div />;

  const handleAttestationChange = (
    sectionId: string,
    question: Types.KeyedQuestion,
    attestation: AttestationOption,
  ): void => {
    if (!readonly) {
      if (attestation === 'COMPLIANT' && question.allowDocuments && isEmpty(question.attestation?.documents)) {
        setPendingAttestation({ ...pendingAttestation, [`${sectionId}${question.id}`]: attestation });
      } else if (attestation !== 'COMPLIANT') {
        setPendingAttestation({ ...pendingAttestation, [`${sectionId}${question.id}`]: attestation });
      } else {
        createAttestation(sectionId, question.id, attestation);
        setPendingAttestation(omit(pendingAttestation, `${sectionId}${question.id}`));
      }
    }
  };

  const handleExplanationChange = (sectionId: string, questionId: string, attestation: AttestationOption, explanation: string): void => {
    if (!readonly && !isEmpty(explanation)) {
      createAttestation(sectionId, questionId, attestation, explanation);
    }
  };

  const renderExplanation = (
    selectedSection: Types.KeyedSection,
    question: Types.KeyedQuestion,
    selectedAttestation?: AttestationOption,
  ): React.ReactElement<any> | null => {
    if (selectedAttestation) {
      return (
        <Explanation
          initValue={question.attestation.explanation || ''}
          handleUpdate={handleExplanationChange}
          section={selectedSection.id}
          question={question.id}
          attestation={selectedAttestation}
        />
      );
    }
    return <div />;
  };

  const content = isInstructionsOpen ? (
    <SectionContainer>
      <InstructionsTitleBar>
        <InstructionsTitle>
          {t('instructions')}
        </InstructionsTitle>
        <CloseIcon onClick={(): void => setIsInstructionsOpen(false)}>
          <Icons.fa.FaTimes size="1.6rem" color={colors.mainText} />
        </CloseIcon>
      </InstructionsTitleBar>
      <Markdown>
        { instructions }
      </Markdown>
    </SectionContainer>
  ) : (
    <SectionContainer>
      {reportError && <Error error={reportError} />}
      <SectionTitle>{section.name}</SectionTitle>

      <ContentBox>
        <SectionTableHeader columnLength={size(section.columnNames)}>
          {map(section.columnNames, (columnName: string) => (
            <SectionHeaderItem key={columnName}>{columnName}</SectionHeaderItem>
          ))}
          <SectionHeaderItem>{t('attestationHeaderLabel')}</SectionHeaderItem>
        </SectionTableHeader>

        {map(section.questions, (question: Types.KeyedQuestion) => {
          const pendingAttest = pendingAttestation[`${section.id}${question.id}`];
          const selectedAttestation = pendingAttest || question.attestation.attestation;

          return (
            <SectionQuestionRow key={question.id} columnLength={size(question.columns)}>
              {map(question.columns, (column: string) => (
                <SectionQuestionItem key={column}>
                  {column}
                </SectionQuestionItem>
              ))}

              <SectionQuestionItem>
                <AttestationWrapper gridSize={question.allowNA ? 3 : 2}>
                  <AttestationButton
                    onClick={(): void => handleAttestationChange(section.id, question, 'COMPLIANT')}
                    attestation={selectedAttestation}
                    isSelected={selectedAttestation === 'COMPLIANT'}
                    isInactive={selectedAttestation && selectedAttestation !== 'COMPLIANT'}
                  >
                    {t('compliant')}
                  </AttestationButton>
                  <AttestationButton
                    onClick={(): void => handleAttestationChange(section.id, question, 'NON_COMPLIANT')}
                    attestation={selectedAttestation}
                    isSelected={selectedAttestation === 'NON_COMPLIANT'}
                    isInactive={selectedAttestation && selectedAttestation !== 'NON_COMPLIANT'}
                  >
                    {t('non_compliant')}
                  </AttestationButton>
                  {question.allowNA && (
                    <AttestationButton
                      onClick={(): void => handleAttestationChange(section.id, question, 'NOT_APPLICABLE')}
                      attestation={selectedAttestation}
                      isSelected={selectedAttestation === 'NOT_APPLICABLE'}
                      isInactive={selectedAttestation && selectedAttestation !== 'NOT_APPLICABLE'}
                    >
                      {t('not_applicable')}
                    </AttestationButton>
                  )}
                </AttestationWrapper>

                {renderExplanation(section, question, selectedAttestation)}

                {question.allowDocuments && (
                  <DocumentsContainer>
                    <DocumentDescription>{question.documentDescription}</DocumentDescription>
                      {pendingAttest === 'COMPLIANT' && isEmpty(question.attestation.documents) && (
                        <DocumentError>{t('docRequiredError')}</DocumentError>
                      )}
                    <DocumentsList>
                      {map(question.attestation.documents, (document: Types.Document) => (
                        <DocumentItem key={document.id}>
                          <Icons.fa.FaFileAlt />
                          <DocumentName>{document.name}</DocumentName>

                          {!readonly && (
                            <DocumentRemoveButton
                              onClick={(): void => removeDocument(section.id, question.id, document.id)}
                            >
                              <Icons.fa.FaTrashAlt color={colors.error} />
                            </DocumentRemoveButton>
                          )}
                        </DocumentItem>
                      ))}
                    </DocumentsList>
                    { !readonly && (
                      <AddFileInput htmlFor={`addFile-${section.id}-${question.id}`}>
                        <Icons.md.MdFileUpload />
                        { t('uploadFile') }
                        <input
                          type="file"
                          id={`addFile-${section.id}-${question.id}`}
                          onChange={(event: any): void => handleUpload(event, section.id, question.id)}
                        />
                      </AddFileInput>
                    )}
                  </DocumentsContainer>
                )}
              </SectionQuestionItem>
            </SectionQuestionRow>
          );
        })}
      </ContentBox>
    </SectionContainer>
  );

  return (
    <MainArea>
      <TitleBanner>
        <CloseButton to="/">
          <Icons.fa.FaTimes color={colors.inactive} size="2rem" />
        </CloseButton>
        <Button isActive className="triggerChat">
          <Icons.fa.FaComment />
          {t('contactSupport')}
        </Button>
        {!!instructions && (
          <Button onClick={(): void => setIsInstructionsOpen(!isInstructionsOpen)}>
            <Icons.fa.FaListOl />
            {t('viewInstructions')}
          </Button>
        )}
      </TitleBanner>
      <ContentWrapper>
        {content}
      </ContentWrapper>
    </MainArea>
  );
};

export default SectionEdit;

import {
  React, styled, useTranslation, Icons, useAuth0, GlobalContext,
} from '@riskforge/platform-web';
import { colors } from '@riskforge/jawa-theme';
import { GlobalContext as JawaContext } from 'contexts';

import Modal from 'components/modal';
import Form from 'components/form';
import Spinner from 'components/spinner';

const SettingsModalContainer = styled.div`
  width: 30rem;
  padding: 2rem;
  & > * {
    margin: 0 0 1rem;
  }
`;
const SettingsBtns = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 1rem;
  border-top: 1px solid ${colors.shadow};
  margin: 2rem 0 0;
`;
const CloseIcon = styled.div`
  font-size: 1.5rem;
  color: ${colors.inactive};
  cursor: pointer;
  user-select: none;
  &:hover {
    color: ${colors.active};
  }
`;
const ModalTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
`;
const FormSuccessMessage = styled.div`
  padding: 5rem 2rem;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  color: ${colors.success};
`;
const Button = styled.div<{ isWarn?: boolean }>`
  cursor: pointer;
  user-select: none;
  background: ${(p) => (p.isWarn ? colors.error : colors.success)};
  color: white;
  padding: 0.5rem;
  width: 10rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  font-weight: 700;
`;

interface SettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const getHost = (loc = window.location.href): string => {
  if (/report-dev.tc.comply.cloud/.test(loc)) {
    return 'https://www.report-dev.tc.comply.cloud';
  }

  if (/report-prod.tc.comply.cloud/.test(loc)) {
    return 'https://www.report-prod.tc.comply.cloud';
  }

  if (/report.trivecapital.com/.test(loc)) {
    return 'https://report.trivecapital.com';
  }

  return 'http://localhost:3002';
};

const SettingsModal: React.FC<SettingsModalProps> = ({ isOpen, onClose }: SettingsModalProps) => {
  const { t } = useTranslation();
  const { actionCreator } = React.useContext(GlobalContext) as JawaContext;
  const { getTokenSilently, user, logout } = useAuth0();
  const [isUpdateSuccessful, setIsUpdateSuccessful] = React.useState(false);

  const handleLogout = (): void => {
    sessionStorage.clear();
    logout({
      returnTo: getHost(),
    });
  };

  const updateUser = async (name: string, email: string): Promise<void> => {
    const token = (await getTokenSilently()) as string;
    const response = await actionCreator
      .updateUser(token, {
        name,
        email,
      })
      .catch();
    setIsUpdateSuccessful(response);
  };

  const handleSubmitUpdateUser = (data: any): void => {
    const { name, email } = data;
    updateUser(name, email);
  };

  const changePasswordLink = async (): Promise<void> => {
    const token = (await getTokenSilently()) as string;
    const url = await actionCreator.changePasswordLink(token).catch();
    window.location.replace(url);
  };

  let formContent = (
    <Form
      onSubmit={handleSubmitUpdateUser}
      fields={[
        {
          type: 'text',
          name: 'name',
          initialValue: user?.name || '',
          ref: { required: true, minLength: 1 },
          errors: { required: t('nameFieldRequired'), min: t('nameFieldRequired') },
        },
        {
          type: 'text',
          name: 'email',
          initialValue: user?.email || '',
          ref: { required: true, minLength: 1 },
          errors: { required: t('emailFieldRequired'), min: t('emailFieldRequired') },
        },
      ]}
    />
  );

  if (!user) {
    formContent = <Spinner />;
  } else if (isUpdateSuccessful) {
    formContent = <FormSuccessMessage>{t('userUpdateSuccessMsg')}</FormSuccessMessage>;
  }

  const ModalContent = (
    <>
      <ModalTitle>
        {t('userSettingsTitle')}
        <CloseIcon
          onClick={(): void => {
            setIsUpdateSuccessful(false);
            onClose();
          }}
        >
          <Icons.fa.FaTimes />
        </CloseIcon>
      </ModalTitle>

      <SettingsModalContainer>
        {formContent}

        <SettingsBtns>
          <Button onClick={changePasswordLink}>{t('changePassword')}</Button>
          <Button onClick={handleLogout} isWarn>
            {t('logoutBtn')}
          </Button>
        </SettingsBtns>
      </SettingsModalContainer>
    </>
  );
  // }

  return <Modal isOpen={isOpen} content={ModalContent} onClose={onClose} width="lg" />;
};

export default SettingsModal;

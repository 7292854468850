import { Types } from '@riskforge/jawa-core';
import {
  Icons, React, useTranslation, styled,
} from '@riskforge/platform-web';
import { Acknowledge, ReportInfo, Modal } from 'components';
import human from 'humanparser';
import { colors } from '@riskforge/jawa-theme';
import Markdown from 'markdown-to-jsx';

const Wrapper = styled.div`
  height: auto;
  overflow-y: auto;
  > *:first-child {
    margin-bottom: 3rem;
  }
`;
const InstructionsWrapper = styled.div`
  color: ${colors.mainText};
  padding: 1rem;
`;
const TitleRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 0 3rem;
`;
const InstructionsTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
`;
const CloseIcon = styled.div`
  cursor: pointer;
  user-select: none;
`;

interface ReportEntryProps {
  user: Types.User;
  report: Types.KeyedReport;
  onSubmit: () => void;
}

const ReportEntry: React.FunctionComponent<ReportEntryProps> = ({
  user,
  report,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [isInstructionsOpen, setIsInstructionsOpen] = React.useState<boolean>(false);

  const reportEntryMessage = t('reportEntryMessage', {
    name: human.parseName(user.fullName).firstName,
    label: report.label,
  });

  const readInstructionsAction = {
    label: t('readInstructions'),
    icon: Icons.fa.FaList,
    handler: (): void => setIsInstructionsOpen(true),
  };

  const acknowledgements = [
    { label: t('ack1'), name: 'ack1' },
    { label: t('ack2'), name: 'ack2' },
    { label: t('ack3'), name: 'ack3' },
  ];

  const ModalContent = (
    <InstructionsWrapper>
      <TitleRow>
        <InstructionsTitle>{t('instructions')}</InstructionsTitle>
        <CloseIcon onClick={(): void => setIsInstructionsOpen(false)}>
          <Icons.fa.FaTimes color={colors.mainText} size="1.5rem" />
        </CloseIcon>
      </TitleRow>
      {report?.instructions && (
        <Markdown>{report?.instructions}</Markdown>
      )}
    </InstructionsWrapper>
  );

  return (
    <Wrapper>
      <ReportInfo
        title={reportEntryMessage}
        actions={[readInstructionsAction]}
      />
      <Acknowledge
        title={t('reportEntryAck')}
        submitLabel={t('reportEntryAckSubmit')}
        acknowledgements={acknowledgements}
        handler={onSubmit}
      />
      <Modal
        isOpen={isInstructionsOpen}
        content={ModalContent}
        onClose={(): void => setIsInstructionsOpen(false)}
        width="xl"
      />
    </Wrapper>
  );
};

export default ReportEntry;

import { colors } from '@riskforge/jawa-theme';
import {
  React,
  styled,
} from '@riskforge/platform-web';
import TriveLogoImg from 'components/TriveLogo/trive.png';

interface TriveLogoProps {
  color?: string;
}

interface FormStyleProps {
  logoColor?: string;
}

const LogoWrapper = styled.div<FormStyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  height: 6rem;
`;
export const StyledLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const TriveLogo: React.FC<TriveLogoProps> = ({ color }: TriveLogoProps) => {
  const logoColor = color || colors.text;
  return (
    <LogoWrapper logoColor={logoColor}>
      <StyledLogo src={TriveLogoImg} alt="Trive Capital" />
    </LogoWrapper>
  );
};

export default TriveLogo;

import {
  React,
  Icons,
  styled,
  useForm,
  useTranslation,
} from '@riskforge/platform-web';
import { colors, layout } from '@riskforge/jawa-theme';
import Button from './button';

const Wrapper = styled.div<{ isHidden?: boolean }>`
  height: ${(p) => (p.isHidden ? '1px' : 'calc(100vh - 4rem)')};
  overflow-y: ${(p) => (p.isHidden ? 'hidden' : 'auto')};

  transition: all 0.3s ease-in-out;
  background: ${colors.background};
  width: 100vw;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-height: 540px) {
    overflow-y: hidden;
  }
`;
const SignBoxWrapper = styled.div`
  min-height: 25rem;
`;
const SignBox = styled.div`
  background: ${colors.widgetBackground};
  padding: 2rem 8rem;
  text-align: center;
  width: 50rem;
  margin: 0 auto;
`;
const TopIcon = styled.div`
  margin: 0 auto -3rem;
  height: 6rem;
  width: 6rem;
  border-radius: 3rem;
  background: ${colors.logoHighlight};
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    margin-left: 1rem;
  }
`;
const IconBg = styled.div`
  margin: -5rem auto 1rem;
  height: 6rem;
  width: 6rem;
  border-radius: 3rem;
  background: ${colors.logoHighlight};
`;
const SignTitle = styled.div`
  font-weight: 700;
  font-size: 1.6rem;
  margin: 0 0 1rem;
  @media (min-height: 600px) {
    font-size: 2rem;
  }
`;
const SignText = styled.div`
  font-weight: 700;
  margin: 0 0 1rem;
`;
const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ButtonIcon = styled.div``;
const SubmitButton = styled(Button)`
  background-color: ${colors.ok};
  border-radius: ${layout.widgetBorderRadius};
  width: 14rem;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  justify-content: center;
`;
const CancelButton = styled(Button)`
  margin: 2rem 0 0 -38rem;
  background-color: ${colors.widgetBackground};
  border-radius: ${layout.widgetBorderRadius};
`;
const Label = styled.label`
  display: block;
  width: 100%;
`;

const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;

  @media (min-height: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;
const FormField = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 3rem 1rem;

  label {
    font-size: 1rem;
    line-height: 1.4rem;
    font-weight: 300;
    text-align: left;
    @media (min-height: 600px) {
      font-size: 1.3rem;
      line-height: 1.75rem;
    }
  }
  input[type=checkbox] {
    width: 3rem;
    height: 3rem;
    margin-right: 0.75rem;
  }
  input[type=text] {
    font-family: 'Nothing You Could Do', cursive;
    height: 3rem;
    width: 100%;
    border: 3px solid ${colors.text};
    border-radius: 0.5rem;
    background: transparent;
    color: ${colors.text};
    text-align: center;
    font-size: 1.6rem;
    @media (min-height: 600px) {
      font-size: 2rem;
    }
  }
`;
const FormError = styled.div`
  color: ${colors.warn};
  margin-bottom: 1rem;
`;

const ReportSign: React.FunctionComponent<ReportSignProps> = ({
  handler,
  handleCancel,
  reportName,
  clientName,
  className,
  isHidden,
}) => {
  const { t } = useTranslation();
  const { handleSubmit, register, errors } = useForm();

  return (
    <Wrapper isHidden={isHidden} className={className}>
      <SignBoxWrapper>
        <TopIcon><Icons.fa.FaFileSignature size="3rem" /></TopIcon>

        <SignBox>
          <IconBg />
          <SignTitle>{t('signTitle')}</SignTitle>
          <SignText>{t('signMessage', { clientName, reportName })}</SignText>
          <SignText>{t('signExplain')}</SignText>
          <StyledForm onSubmit={handleSubmit(handler)}>
            <FormField key="signatureCheck">
              <input type="checkbox" name="signatureCheck" ref={register({ required: true })} />
              <Label htmlFor="signatureCheck">
                {t('certifyAnswers')}
              </Label>
            </FormField>
            <FormError>{errors.signatureCheck ? t('requiredField') : undefined}</FormError>
            <FormWrapper>
              <FormField key="signatureName">
                <input type="text" name="signatureName" placeholder="Your Full Name" ref={register({ required: true })} />
              </FormField>
              <FormError>{errors.signatureName ? t('requiredField') : undefined}</FormError>
              <SubmitButton label={t('signSubmit')} icon={ButtonIcon} handler={handler} />
            </FormWrapper>
          </StyledForm>
        </SignBox>
      </SignBoxWrapper>
      <CancelButton label={t('returnToReport')} icon={ButtonIcon} handler={handleCancel} />
    </Wrapper>
  );
};

interface ReportSignProps {
  reportName: string;
  clientName: string;
  isHidden: boolean;
  className?: string;
  handler: (formInputs: any) => void;
  handleCancel: () => void;
}


export default ReportSign;

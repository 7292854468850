import { React, styled, useTranslation } from '@riskforge/platform-web';

const Brand: React.FunctionComponent<BrandProps> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <Wrapper className={className}>
      {t('brand')}
    </Wrapper>
  );
};

interface BrandProps {
  className?: string;
}

const Wrapper = styled.div`
  white-space: nowrap;
  font-size: 2rem;
  line-height: 2.5rem;
  font-family: 'MrsEavesXLSerifOT-Reg';
  text-transform: uppercase;
  letter-spacing: 0.35rem;
  @media (max-width: 480px) {
    font-size: 1.3rem;
    line-height: 1.6rem;
    letter-spacing: 0.2rem;
  }
`;

export default Brand;

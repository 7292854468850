import { Types } from '@riskforge/jawa-core';
import {
  map, values, subtract, divide, filter, size, flatMap,
} from 'lodash';
import { colors } from '@riskforge/jawa-theme';
import {
  Icons,
  React,
  styled,
  useTranslation,
} from '@riskforge/platform-web';
import Progress from './progress';

function daysUntil(date: Date): number {
  const oneDay = 1000 * 60 * 60 * 24;
  const today = new Date();
  return Math.ceil((date.getTime() - today.getTime()) / (oneDay));
}

const ReportNav: React.FunctionComponent<ReportNavProps> = ({
  className, report, activeReportIndex, setActiveReportIndex,
}) => {
  const { t } = useTranslation();
  const dueIn = daysUntil(report.due);

  const allQuestions = flatMap(values(report.sections), (sec: Types.Section) => values(sec.questions));
  const questionsComplete = size(filter(allQuestions, (q: any) => !!q.attestation?.attestation));
  const reportProgress = report.progress ? questionsComplete / report.progress.totalQuestions : 0;

  const dueMessage = dueIn > 0
    ? t('dueInMsg', { count: dueIn })
    : (dueIn < 0
      ? t('wasDueMsg', { count: Math.abs(dueIn) })
      : t('dueTodayMsg'));

  return (
    <Wrapper className={className}>
      <NavHeader>
        <ReportTitle>{t('report', { reportName: report.label })}</ReportTitle>
        <DueDate>{ dueMessage }</DueDate>
        <Progress progress={reportProgress} label />
      </NavHeader>
      <SectionsSubtitle>{t('reportSections')}</SectionsSubtitle>
      {map(values(report.sections), (sec: Types.Section, index: number) => {
        const answeredQuestions = size(filter(sec.questions, (q) => !!q.attestation?.attestation));

        const remainingFraction = divide(answeredQuestions, sec?.progress?.totalQuestions);
        const remainingCount = subtract(sec?.progress?.totalQuestions, answeredQuestions);
        const statusMessage = remainingCount ? t('sectionIncomplete', { count: remainingCount }) : t('sectionComplete');
        const progressIcon = remainingFraction === 0
          ? <Icons.fa.FaRegCircle size="2rem" />
          : (remainingFraction === 1
            ? <Icons.fa.FaCheckCircle size="2rem" />
            : <Icons.fa.FaAdjust size="2rem" />);
        return (
          <SectionNavItem
            key={`nav-rpt-sect-${sec.id}`}
            isActive={activeReportIndex === index}
            fraction={remainingFraction}
            onClick={(): void => setActiveReportIndex(index)}
          >
            <SectionIcon>{progressIcon}</SectionIcon>
            <div>
              <SectionTitle>{ sec.name }</SectionTitle>
              <SectionStatus>{ statusMessage }</SectionStatus>
            </div>
          </SectionNavItem>
        );
      })}
    </Wrapper>
  );
};

interface ReportNavProps {
  className?: string;
  report: Types.KeyedReport;
  activeReportIndex: number;
  setActiveReportIndex: (newReportIndex: number) => void;
}

const Wrapper = styled.div`
  background-color: ${colors.leftNavBackground};
  color: ${colors.text};
  height: auto;
  overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  &::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
  }
  & > *:last-child {
    margin-bottom: 8rem;
  }

`;
const NavHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 3rem 1rem;
`;
const ReportTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0 0 0.5rem;
`;
const DueDate = styled.div`
  font-weight: 200;
`;
const SectionsSubtitle = styled.div`
  font-weight: 600;
  font-size: 1.2rem;
  margin: 0 1rem 0.5rem;
`;
const SectionNavItem = styled.div<{ isActive: boolean; fraction: number }>`
  display: grid;
  grid-template-columns: 2rem auto;
  grid-gap: 1rem;
  opacity: ${(p) => (p.isActive ? '1' : '0.7')};
  background: ${(p) => (p.isActive ? colors.darkerLeftNav : 'transparent')};
  cursor: pointer;
  user-select: none;
  padding: 1rem;
  color: ${({ fraction }) => (fraction === 0 ? colors.text : (fraction === 1 ? colors.success : colors.warn))};
`;
const SectionIcon = styled.div`
  align-self: center;
`;
const SectionTitle = styled.div`
  font-size: 1.5rem;
  padding: 0.1rem 0;
`;
const SectionStatus = styled.div`
  font-size: 0.8rem;
  color: ${colors.text};
`;

export default ReportNav;

import { ActionCreator } from '@riskforge/jawa-app-state';

interface JawaContext {
  token: string;
  actionCreator: ActionCreator;
}

const JawaContext = {
  actionCreator: /^.+.comply.cloud/.test(window.location.href) || /^.+.trivecapital/.test(window.location.href)
    ? new ActionCreator('babyyoda-staging.comply.cloud', 443, 'https')
    : new ActionCreator('localhost', 3000, 'http'),
};

export default JawaContext;

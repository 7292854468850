import './global.css';

import { store } from '@riskforge/jawa-app-state';
import { App, ServiceWorker } from '@riskforge/platform-web';

import AuthConfig from './auth.json';
import { GlobalContext } from './contexts';
import * as Views from './views';

const routes = [
  {
    path: '/',
    exact: true,
    default: true,
    component: Views.ReportList,
    authenticated: true,
  },
  {
    path: '/report/:reportId',
    component: Views.Report,
    authenticated: true,
  },
];

App.render(routes, store, GlobalContext, AuthConfig, {});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
ServiceWorker.unregister();

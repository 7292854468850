import {
  React,
  styled,
  useForm,
  useTranslation,
} from '@riskforge/platform-web';
import { colors, layout } from '@riskforge/jawa-theme';

import Button from './button';

const StyledForm = styled.form`
`;

const Label = styled.label`
  display: block;
  font-size: 0.8rem;
  width: 100%;
`;

const ButtonIcon = styled.div``;

const StyledButton = styled(Button)`
  background-color: ${colors.ok};
  border-radius: ${layout.widgetBorderRadius};
  margin: 0 auto;
`;

const Wrapper = styled.div`
  width: 80%;
  margin: 0 10% 5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  h3 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 3.5rem;
    margin-bottom: 1rem;
  }
`;

const FormField = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  label {
    font-size: 1.2rem;
    line-height: 1.75rem;
    font-weight: 300;
  }
  input[type=checkbox] {
    width: 2rem;
    height: 2rem;
    margin-right: 0.75rem;
  }
`;

interface AcknowledgeProps {
  title: string;
  submitLabel: string;
  acknowledgements: { label: string; name: string }[];
  handler: any; // OnSubmit<Record<string, any>>
  className?: string;
}

const Acknowledge: React.FunctionComponent<AcknowledgeProps> = ({
  className,
  handler,
  title,
  submitLabel,
  acknowledgements,
}) => {
  const { t } = useTranslation();
  const { handleSubmit, register, errors } = useForm();

  return (
    <Wrapper className={className}>
      <h3>{title}</h3>
      <StyledForm onSubmit={handleSubmit(handler)}>
        {acknowledgements.map(({ label, name }) => (
          <FormField key={name}>
            <input type="checkbox" name={name} ref={register({ required: true })} />
            <Label htmlFor={name}>{label}</Label>
            {errors[name] ? <span>{t('requiredField')}</span> : undefined}
          </FormField>
        ))}
        <StyledButton label={submitLabel} icon={ButtonIcon} handler={handler} />
      </StyledForm>
    </Wrapper>
  );
};

export default Acknowledge;

import { Icon, React, styled } from '@riskforge/platform-web';

const Button: React.FunctionComponent<ButtonProps> = ({
  label, icon: ButtonIcon, handler, className, disabled = false,
}) => (
  <StyledButton className={className} onClick={handler} disabled={disabled}>
    <ButtonIcon />
    <span>{label}</span>
  </StyledButton>
);

interface ButtonProps {
  label: string;
  icon: Icon;
  handler: (arg: any) => void;
  disabled?: boolean;
  className?: string;
}

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  * {
    color: rgb(240, 240, 240);
    font-weight: 700;
    font-size: 1.2rem;
  }
  padding: 0.7rem 1.2rem;
  > svg {
    margin-right: 1rem;
  }
  &:hover {
    * {
      color: rgb(255, 255, 255);
    }
    box-shadow: 0px 0px 20px 0px rgba(22, 64, 162, 0.5);
  }
`;

export default Button;

import {
  React, styled,
} from '@riskforge/platform-web';
import { colors } from '@riskforge/jawa-theme';
import { Dialog } from '@material-ui/core';
import { isEmpty } from 'lodash';

interface ModalProps {
  isOpen: boolean;
  title?: string | React.ReactElement<any>;
  content: string | React.ReactElement<any>;
  buttons?: React.ReactElement<any>;
  onClose?: () => void;
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  hasOverlay?: boolean;
}

const StyledTitle = styled.div`
  font-size: 1.2rem;
  height: 1.5rem;
  font-weight: 600;
  margin: 0;
  border-bottom: 1px solid ${colors.inactive};
  padding: 1rem 1rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;
const InnerDialog = styled.div`
  padding: 2rem 2rem 2rem;
`;
const StyledContent = styled.div`
  margin: 0 1rem 1rem;
`;
const AltWrapper = styled.div`
  position: absolute;
  top: 0;
  height: 100vh;
  left: 0;
  width: 100vw;
  z-index: 160;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const AltModal = styled.div`
  width: 50%;
  background: ${colors.contentBoxBg};
  color: ${colors.mainText};
  box-shadow: 0 0.3rem 0.5rem -0.3rem rgba(0, 0, 0, 0.2), 0 1rem 1rem 0.1rem rgba(0, 0, 0, 0.14),
    0 0.3rem 1rem 0.3rem rgba(0, 0, 0, 0.12);
  border-radius: 0.5rem;
`;
const Overlay = styled.div`
  position: absolute;
  top: 0;
  height: 100vh;
  left: 0;
  width: 100vw;
  z-index: 150;
  background: ${colors.reportBackground};
  opacity: 0.7;
  transition: all 0.3s ease-in-out;
`;
export const ActionBtns = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  & > * {
    margin-left: 1rem;
  }
`;

const Modal: React.FC<ModalProps> = ({
  isOpen, title, content, buttons, onClose, width, hasOverlay,
}: ModalProps) => {
  if (hasOverlay && isOpen) {
    return (
      <>
        <AltWrapper>
          <AltModal>
            <InnerDialog>
              {!isEmpty(title) && <StyledTitle>{title}</StyledTitle>}
              <StyledContent>{content}</StyledContent>
              {!isEmpty(buttons) && <ActionBtns>{buttons}</ActionBtns>}
            </InnerDialog>
          </AltModal>
        </AltWrapper>

        {hasOverlay && <Overlay />}
      </>
    );
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={width}>
      <InnerDialog>
        {!isEmpty(title) && <StyledTitle>{title}</StyledTitle>}
        <StyledContent>{content}</StyledContent>
        {!isEmpty(buttons) && <ActionBtns>{buttons}</ActionBtns>}
      </InnerDialog>
    </Dialog>
  );
};

export default Modal;

import { Types } from '@riskforge/jawa-core';
import { React, styled } from '@riskforge/platform-web';

const Signature: React.FunctionComponent<SignatureProps> = ({ className, signature }) => (
  <Wrapper className={className}>
    <p>{signature.name}</p>
    <p>
      eSig:
      {' '}
      <code>{signature.hash}</code>
    </p>
  </Wrapper>
);

interface SignatureProps {
  className?: string;
  signature: Types.Signature;
}

const Wrapper = styled.div`
    > *:first-child {
        font-family: 'Nothing You Could Do', cursive;
        font-size: 1.75rem;
        line-height: 2rem;
        @media (max-width: 720px) {
          font-size: 1rem;
          line-height: 1.2rem;
        }
    }
    > *:last-child, > *:last-child * {
        opacity: 50%;
        @media (max-width: 720px) {
          font-size: 0.75rem;
          line-height: 1rem;
        }
    }
    > * {
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;

export default Signature;

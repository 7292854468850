import { text } from '@riskforge/jawa-theme';
import { styled } from '@riskforge/platform-web';

const ReportTitle = styled.h1`
  width: 95%;
  font-size: ${text.reportList.titleSize} !important;
  line-height: ${text.reportList.titleLine} !important;
  flex: 0 0 ${text.reportList.titleLine} !important;
  @media (max-width: 720px) {
    display: none;
  }
`;

export default ReportTitle;

import { colors, layout } from '@riskforge/jawa-theme';
import { Icon, React, styled } from '@riskforge/platform-web';

import Button from './button';

const ReportInfo: React.FunctionComponent<ReportInfoProps> = ({ className, title, actions }) => (
  <Wrapper className={className}>
    <h1>{title}</h1>
    {!actions ? undefined : actions.map(({ icon, label, handler }) => <StyledButton key={`action-${label}`} label={label} icon={icon} handler={handler} />)}
  </Wrapper>
);

type Action = {
  label: string;
  icon: Icon;
  handler: () => void;
};

interface ReportInfoProps {
  className?: string;
  title: string;
  actions?: Action[];
}

const Wrapper = styled.div`
  background-color: ${colors.widgetBackground};
  width: 80%;
  margin: 0 10%;
  height: 12rem;
  border-radius: ${layout.widgetBorderRadius};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  > * {
    max-width: 75%;
    text-align: center;
  }
  h1 {
    font-size: 2rem;
    font-weight: 700;
  }
`;

const StyledButton = styled(Button)`
  background-color: ${colors.primary};
  border-radius: ${layout.widgetBorderRadius};
`;

export default ReportInfo;
